import React, { Context, useState } from "react";

const NavScrollContext: Context<{
  headerAnimation: boolean;
  setHeaderAnimation: (value: boolean) => void;
}> = React.createContext<{
  headerAnimation: boolean;
  setHeaderAnimation: (value: boolean) => void;
}>({
  headerAnimation: false,
  setHeaderAnimation: (value: boolean) => {},
});

const NavScrollProvider: React.FC = ({ children }) => {
  const [headerAnimation, setHeaderAnimation] = useState(false);

  return (
    <NavScrollContext.Provider
      value={{
        headerAnimation,
        setHeaderAnimation,
      }}>
      {children}
    </NavScrollContext.Provider>
  );
};

export default NavScrollContext;
export { NavScrollProvider };
